import WHITE_PIN from "../Constants.js";
import React from "react";
import beacon from "../api/logClick.js";
/**
 * A vanilla post is one that has no child links.
 * Action upon clicking a vanilla post is to follow the single
 * link provided in IG.
 */
const VanillaPost = React.forwardRef(({ post }, ref) => {
  const content = (
    <div className="outer-div">
      <a
        href={post.external_url}
        target="_self"
        onClick={() => beacon(post.id, post.username, post.external_url)}
        id={post.id}
      >
        <img
          className="post-image"
          id={post.id}
          src={post.app_thumbnail_url}
          alt={post.caption}
          ref={ref}
        ></img>
      </a>
      {post.priority < 99 && WHITE_PIN}
    </div>
  );
  return content;
});

export default VanillaPost;
