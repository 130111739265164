import { useState, useEffect } from "react";
import { retrieveMedia } from "../api/retrieveMedia";

const usePosts = (
  pageNum = 0,
  lastEvaluatedKey = undefined,
  userId = undefined,
  source
) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState({});
  const [hasNextPage, setHasNextPage] = useState(false);
  const [newLastEvaluatedKey, setNewLastEvaluatedKey] = useState(undefined);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setError({});

    const controller = new AbortController();
    const { signal } = controller;

    retrieveMedia(pageNum, lastEvaluatedKey, { signal }, userId, source)
      .then((response) => {
        setResults((prev) => [...prev, ...response.data]);
        setHasNextPage(
          !(
            typeof response.lastEvaluatedKey === "undefined" ||
            response.lastEvaluatedKey === "undefined"
          )
        );
        setIsLoading(false);
        setNewLastEvaluatedKey(response.lastEvaluatedKey);
      })
      .catch((e) => {
        setIsLoading(false);
        if (signal.aborted) return;
        setIsError(true);
        setError({ message: e.message });
      });
    return () => controller.abort();
  }, [pageNum]); //close useEffect

  return {
    isLoading,
    isError,
    error,
    results,
    hasNextPage,
    newLastEvaluatedKey,
  };
};

export default usePosts;
