import axios from "axios";

export const retrieveImageLinks = async (post) => {
  let response;
  //Encoding is important since post.id has a '#':  mediaId#3742637.  The # is interpreted
  //as a fragment w/out the encoding.
  const encodedMediaId = encodeURIComponent(post.id);
  const endpoint = `https://${process.env.REACT_APP_DOMAIN}/api/imagelinks?m=${encodedMediaId}&u=${post.username}`;
  console.log(endpoint);
  response = await axios.get(endpoint);
  return response;
};
