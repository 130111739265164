import React from "react";
import styled from "styled-components";

const Tab = styled.button`
  font-size: 14.3px;
  padding: 10px 60px;
  curosr: pointer;
  opacity: 0.6;
  background: white;
  border: none; /* Remove all borders */
  border-bottom: 2px solid transparent; /* Add transparent bottom border */
  outline: 0;
  flex: 1;
  ${({ active }) =>
    active &&
    `
        border-bottom: 2px solid black;
        opacity: 1;
        
        `}
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const types = ["INSTAGRAM", "TIKTOK"];

function TabGroup({ onTabChange, activeTab }) {
  return (
    <>
      <ButtonGroup>
        {types.map((type) => (
          <Tab
            key={type}
            active={activeTab === type}
            onClick={() => onTabChange(type)}
          >
            {type}
          </Tab>
        ))}
      </ButtonGroup>
    </>
  );
}

export default TabGroup;
