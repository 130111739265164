import "./App.css";
import { useEffect, useState } from "react";
import Header from "./components/Header";
import TabGroup from "./components/TabGroup";
import "../src/css/Grid.css";
import Main from "./components/Main";
import GoogleAnalyticsScript from "./components/GoogleAnalyticsScript";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";

/**
 * Construct the primary App view, which consists of Header and Main components.
 * Prior to constructing, retrieve user-specific data from the backend.
 *
 * We only display the TabGroup, which toggles between Instagram and TikTok,
 * if the user has enabled both data sources.
 *
 * @returns The main app
 */
function App() {
  const user = useLoaderData();
  //const [selectedSource, setSelectedSource] = useState("INSTAGRAM");

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  const initialTab = query.get("tab") === "tiktok" ? "TIKTOK" : "INSTAGRAM";
  const [selectedSource, setSelectedSource] = useState(initialTab);

  useEffect(() => {
    document.title = `${user.instagramUsername}`;
  }, [user.instagramUsername]);

  useEffect(() => {
    if (selectedSource === "TIKTOK") {
      navigate(`?tab=tiktok`);
    } else {
      navigate(``);
    }
  }, [selectedSource, navigate]);

  const handleTabChange = (source) => {
    setSelectedSource(source);
  };

  return (
    <>
      <GoogleAnalyticsScript />
      <Header user={user} />
      {user.fbEnabled && user.tiktokEnabled && (
        <TabGroup onTabChange={handleTabChange} activeTab={selectedSource} />
      )}
      {selectedSource === "INSTAGRAM" && user.fbEnabled && (
        <Main user={user} source={"INSTAGRAM"} />
      )}
      {selectedSource === "TIKTOK" && user.tiktokEnabled && (
        <Main user={user} source={"TIKTOK"} />
      )}
    </>
  );
}

export default App;
