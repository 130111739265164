import axios from "axios";

/**
 * This is the API call that lazily loads media for a user.
 * ToDo: Do I need the pageParam?
 */
export const retrieveMedia = async (
  pageParam = 0,
  lastEvaluatedKey = undefined,
  options = {},
  userId = undefined,
  source
) => {
  let response;
  const encoded = encodeURIComponent(lastEvaluatedKey);

  const encodedUserId = userId.replace("#", "%23");

  const endpoint = `https://${process.env.REACT_APP_DOMAIN}/api/retrieve?page=${pageParam}&q=${encoded}&u=${encodedUserId}&s=${source}`;

  response = await axios.get(endpoint);
  if (response.data === null) {
    return { data: [], lastEvaluatedKey: undefined };
  }
  lastEvaluatedKey = response.data.lastEvaluatedKey;
  return { data: response.data.items, lastEvaluatedKey: lastEvaluatedKey };
};
