import React from "react";
import beacon from "../../api/logClick.js";

/**
 * This component is a div.  It appears in the modal and shows all
 * the child links, positioned below the parent picture.
 *
 */
const ImageLinksDiv = ({ imageLinksData }) => {
  return (
    <div className="image-links-grid">
      {imageLinksData.data.map((imageLink, index) => (
        <a
          key={index}
          href={imageLink.linkUrl}
          target="_self"
          onClick={() => beacon(imageLink.id, "placeholder", imageLink.linkUrl)}
        >
          <img src={imageLink.imageUrl} alt={imageLink.imageUrl} />
        </a>
      ))}
    </div>
  );
};

export default ImageLinksDiv;
