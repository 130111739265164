import React, { useState, useEffect } from "react";
import ImageLinksModal from "./imagelinks/ImageLinksModal.js";
import WHITE_PIN from "../Constants.js";
import { retrieveImageLinks } from "../api/retrieveImageLinks.js";
import beacon from "../api/logClick.js";

const PostWithModal = React.forwardRef(({ user, post }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.height = "auto";
    }
    const handleBodyScroll = () => {
      document.body.classList.toggle("modal-open", isModalOpen);
    };
    document.body.addEventListener("scroll", handleBodyScroll);
    return () => {
      document.body.removeEventListener("scroll", handleBodyScroll);
    };
  }, [isModalOpen]);

  const openModal = async () => {
    beacon(post.id, post.username);
    try {
      const imageLinksData = await retrieveImageLinks(post);
      setModalData(imageLinksData);
    } catch (error) {
      console.error("Error fetching image links:", error);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="outer-div">
      <img
        className="post-image"
        id={post.id}
        src={post.app_thumbnail_url}
        alt={post.id}
        ref={ref}
        onClick={openModal}
      />
      {post.priority < 99 && WHITE_PIN}
      {isModalOpen && (
        <ImageLinksModal
          user={user}
          post={post}
          onClose={closeModal}
          isOpen={isModalOpen}
          imageLinksData={modalData}
        />
      )}
    </div>
  );
});

export default PostWithModal;
