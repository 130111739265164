import { retrieveUser } from "../api/retrieveUser";

/**
 * Retrieve the user from the backend.  If the user has
 * been disabled, return will be {}
 * @param {} param0
 * @returns
 */
export default async function userLoader({ params }) {
  const username = params.username;
  const dirtyUser = await retrieveUser(username.toLowerCase());

  if (dirtyUser === "{}") {
    return undefined;
  } else {
    const profilePicObjectKey = dirtyUser.data.Item.profilePicObjectKey;
    const welcome = dirtyUser.data.Item.welcome || "";
    const profilePicUrl =
      profilePicObjectKey !== undefined
        ? `https://${process.env.REACT_APP_DOMAIN}/${profilePicObjectKey}`
        : undefined;

    const cleanUser = {
      userId: dirtyUser.data.Item.PK,
      instagramUsername: dirtyUser.data.Item.instagramUsername,
      profilePicUrl: profilePicUrl,
      welcome: welcome,
      profilePageDisclosure: dirtyUser.data.Item.profilePageDisclosure || "",
      subImageLinksAction: dirtyUser.data.Item.subImageLinksAction || "",
      subImageLinksDisclosure:
        dirtyUser.data.Item.subImageLinksDisclosure || "",
      fbEnabled: dirtyUser.data.Item.fbStatus === "enabled",
      tiktokEnabled: dirtyUser.data.Item.tiktokStatus === "enabled",
    };
    return cleanUser;
  }
}
