import { useState, useCallback } from "react";
import usePosts from "../hooks/usePosts";
import { useRef } from "react";
import Post from "./Post";
import "../css/Grid.css";

const INITIAL_PAGE = 0;

const Main = ({ user, source }) => {
  const [pageNum, setPageNum] = useState(INITIAL_PAGE);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);
  const {
    isLoading,
    isError,
    error,
    results,
    hasNextPage,
    newLastEvaluatedKey,
  } = usePosts(pageNum, lastEvaluatedKey, user.userId, source);

  const intObserver = useRef();
  const lastPostRef = useCallback(
    (post) => {
      if (isLoading) return;
      if (intObserver.current) intObserver.current.disconnect();
      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          setPageNum((prev) => prev + 1);
          setLastEvaluatedKey(newLastEvaluatedKey);
        }
      });
      if (post) intObserver.current.observe(post);
    },
    [isLoading, hasNextPage]
  );

  if (isError) return <p className="center">Error: {error.message}</p>;

  const content = results.map((post, i) => {
    if (results.length === i + 1) {
      return <Post ref={lastPostRef} key={post.id} post={post} user={user} />;
    }
    return <Post key={post} post={post} user={user} />;
  });

  return (
    <>
      <main className="container">{content}</main>
    </>
  );
};

export default Main;
