import "./error-page.css";
//import { retrieveUsers } from "./api/retrieveUsers";
import { useEffect } from "react";

// export async function loader() {
//     return await retrieveUsers();
// }
export default function ErrorPage() {
  console.log("v 20240401");
  useEffect(() => {
    document.title = "Home"; // Set the desired title
  }, []);
  return (
    <>
      <div className="error-header-bar">
        <div className="error-logo-container">
          <img src="pup2.png" alt="Logo" />
        </div>
      </div>
      <div className="error-main-container">
        <div className="error-content">
          <h3>
            <div className="error-contact-message">
              Exciting things are on the way! We're under construction to bring
              you a revolutionary "link in bio" that will turbocharge your
              sales!
            </div>
          </h3>
          <h3>
            <div className="error-contact-message">
              Got questions? Reach out to our sales team at{" "}
              <a href="mailto:shawn@codevariant.com">shawn@codevariant.com</a>.
            </div>
          </h3>
        </div>
      </div>
    </>
  );
}
