import React from "react";
import ImageLinksDiv from "./ImageLinksDiv.js";

/**
 * This is the modal that appears when an InternationalPost is clicked.
 * It contains the PostInternationalLinks component, which contains all the links,
 * as a child.
 */

const ImageLinksModal = ({ user, post, onClose, imageLinksData }) => {
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-verbiage">
          <h3>
            <strong>{user.subImageLinksAction}</strong>
          </h3>
          <div className="multi-image-caption">
            <p>{post.multiImageCaption}</p>
          </div>
        </div>

        {<ImageLinksDiv post={post} imageLinksData={imageLinksData} />}
        <div className="paid-links">
          <p>{user.subImageLinksDisclosure}</p>
        </div>
        <button
          className="close-button-container"
          onClick={onClose}
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
  );
};

export default ImageLinksModal;
