import React from "react";
import "../css/Grid.css";
import VanillaPost from "./VanillaPost.js";
import PostWithModal from "./PostWithModal.js";

const Post = React.forwardRef(({ post, user }, ref) => {
  if (post.displayType === 2) {
    return <PostWithModal post={post} user={user} ref={ref} />;
  }
  return <VanillaPost post={post} ref={ref} />;
});

export default Post;
