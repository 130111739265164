import React from "react";
import "../css/Grid.css";

const Header = ({ user }) => {
  const handle = `@${user.instagramUsername}`;
  return (
    <header>
      <table>
        <tbody>
          <tr>
            {user.profilePicUrl !== undefined ? (
              <td>
                <img
                  src={user.profilePicUrl}
                  alt="Profile"
                  className="avatar"
                />
              </td>
            ) : null}
            <td>
              <p className="ig-handle">{handle}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="call-to-action">
        <b>{user.welcome}</b>
      </p>

      <p className="homepage-disclosure">{user.profilePageDisclosure}</p>
    </header>
  );
};

export default Header;
